.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 70px;

  &-title {
    color: #00c1f3;
    font-family: "bellslim_heavyregular";
    font-size: 40px;
    letter-spacing: 0;
    line-height: 43px;
    text-align: center;
    font-weight: 500;
  }

  &-passw {
    box-sizing: border-box;
    height: 46px;
    width: 259px;
    border: 1px solid #00c1f3;
    border-radius: 28px;
    background-color: #ffffff;
    color: #828282;
    font-family: Helvetica;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    outline: none;
    margin-bottom: 16px;

    &:focus::-webkit-input-placeholder {
      opacity: 0;
    }
  }
}
