.Client {
  .InstructionsPage {
    @extend .flex, .flex--center, .flex--column;
    justify-content: space-evenly;
    position: relative;
    width: 100vw;
    overflow-y: scroll;
    padding: 65px 0 0 0;
    gap: 35px;
    background-color: var(--dark-color);

    &__title {
      @extend .font-bell-heavy;
      font-size: 36px;
      margin-bottom: 5px;
      letter-spacing: 5px;
      text-transform: uppercase;

      @media screen and (min-width: 600px) {
        font-size: 3rem;
        line-height: 3.5rem;
        letter-spacing: 0.5rem;
      }
    }

    &__content {
      @extend .font-bell-heavy;
      width: 85vw;

      position: relative;
      text-align: center;
      font-size: 20px;
      line-height: 1.2;

      @media screen and (min-width: 600px) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }
    &__image {
      width: 85vw;
    }

    &__footer {
      @extend .font-bell-heavy;
      color: var(--lightshow-color);
      font-size: 36px;
      letter-spacing: 5px;
      margin-bottom: 5rem;
      text-transform: uppercase;

      @media screen and (min-width: 600px) {
        font-size: 3rem;
        line-height: 1.5;
        letter-spacing: 0.5rem;
      }
    }
  }
}
