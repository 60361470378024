@import "./fonts.scss";
@import "./buttons.scss";
@import "./helpers.scss";
@import "./page.scss";
@import "./modal.scss";
@import "./animations.scss";
@import "./toolbar.scss";
@import "./timer.scss";
@import "./admin/admin.scss";
@import "./client/client.scss";
@import "./client/survey.scss";
@import "./client/info.scss";
@import "./answerIndicator.scss";
@import "./client/instructions.scss";

:root {
  color-scheme: only light;
  --dark-color: #04071d;
  --light-color: #ffffff;
  --darkshow-color: #070942;
  --lightshow-color: #00c1f3;
  --height: 100%;
}

body {
  height: 100vh;
  height: var(--height);
  margin: 0;
  // overflow: hidden;
}

html {
  margin: 0;
  padding: 0;
  font-family: Helvetica;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #070942;
  color: #ffffff;

  @media (prefers-color-scheme: dark) {
    background-color: #070942;
    color: #ffffff;
  }
}

// reset styles
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior: none;
}

// loading icon
.loading {
  max-height: 100%;
}
