.QuestionPage {
  // @extend .gradient-bg;
  @extend .font-bell-heavy;
  color: #ffffff;
  height: var(--height);
  &__container {
    @extend .flex, .flex--column;

    justify-content: space-around;

    height: 100%;

    .hide {
      opacity: 0;
      pointer-events: none;
      user-select: none;
    }

    .title {
      transition: top 0.5s ease;
    }
    .score {
      transition: opacity 0.5s ease-out 950ms;
    }
    .timer {
      transition: opacity 0.5s ease-in 950ms;
    }

    .options {
      transition: opacity 1s linear 950ms;
    }

    &.announcement {
      .title {
        top: 45%;
        transition: none;

        @media screen and (min-width: 600px) {
          top: 40%;
        }
      }

      .score,
      .timer,
      .options {
        @extend .hide;

        transition: none;
      }
    }

    &.answering {
      .score {
        @extend .hide;
      }

      .instruction {
        display: none;
      }
    }

    &.round_score {
      .instruction {
        display: none;
      }

      .timer {
        @extend .hide;
        // transition: none;
      }
    }
  }

  &__title {
    flex: 1 0 60px;
    font-size: 50px;
    line-height: 60px;
    // padding: 40px 0;
    text-align: center;
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 4px;

    @media screen and (min-width: 600px) {
      font-size: 3.5rem;
      line-height: 3.5rem;
      letter-spacing: 0.5rem;
      top: 8%;
    }
    // @media screen and (min-width: 800px) {
    //   font-size: 4.5rem;
    //   line-height: 4.5rem;
    // }
  }

  &__sub-title {
    flex: 1 0 60px;
    font-size: 36px;
    line-height: 1.2;
    text-align: center;
    color: var(--lightshow-color);
    letter-spacing: 3px;

    @media screen and (min-width: 600px) {
      font-size: 2.5rem;
      line-height: 1.5;
      letter-spacing: 0.4rem;
    }
  }

  &__content {
    @extend .flex, .flex--column, .flex--middle, .flex--center;

    flex: 1 0 200px;
    padding-top: 170px;
    position: relative;

    .score {
      position: absolute;
    }

    @media screen and (min-width: 600px) {
      padding-top: 10rem;
    }
    // @media screen and (min-width: 800px) {
    //   padding-top: 10rem;
    // }
  }

  &__options {
    @extend .flex, .flex--column, .flex--middle;

    flex: 1 0 355px;
    padding: 40px 40px 20px;
    justify-content: space-evenly;

    @media screen and (min-width: 600px) {
      margin: 0 3rem;
      padding-top: 0;
      padding-bottom: 0;
    }

    &-item {
      @extend .font-bell-medium;

      outline: none !important;
      outline-offset: 0px;
      margin-bottom: 20px;
      opacity: 0.9;
      border: 2px solid var(--lightshow-color);
      background-color: var(--darkshow-color);
      color: var(--light-color);
      font-size: 15px;
      cursor: pointer;
      text-align: center;
      font-weight: bold;
      line-height: 20px;
      padding: 15px 20px;

      //background: linear-gradient(159.78deg, #ffffff 0%, #f4f4f4 100%);

      &.selected {
      }

      &.correct,
      &.wrong {
        color: #ffffff;
      }

      &.correct {
        background: linear-gradient(159.78deg, #84cc7e 0%, #10ce00 100%);
      }

      &.wrong {
        background: linear-gradient(159.78deg, #ff8080 0%, #ff3a3a 100%);
      }

      @media screen and (min-width: 600px) {
        font-size: 1.5rem;
        padding: 1rem 2.5rem;
        line-height: 2rem;
        margin: 0 2rem 1rem;
      }

      @media screen and (min-width: 800px) {
        margin: 0 4rem 1rem;
      }
      @media screen and (min-width: 1000px) {
        margin: 0 6rem 1rem;
      }
    }
  }

  &__score {
    text-align: center;

    &-round {
      font-size: 65px;
      line-height: 70px;
      margin-bottom: 20px;

      @media screen and (min-width: 600px) {
        font-size: 8rem;
        line-height: 8rem;
      }
    }

    &-total {
      font-size: 40px;
      line-height: 43px;
      text-align: center;
      color: var(--lightshow-color);
      text-transform: uppercase;
      letter-spacing: 4px;

      @media screen and (min-width: 600px) {
        font-size: 2.5rem;
        line-height: 3rem;
        letter-spacing: 0.3rem;
      }
    }
  }
}
