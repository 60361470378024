.Client {
  .InfoPage {
    @extend .flex, .flex--center, .flex--middle, .flex--column;
    background-color: #ffffff;
    width: 100vw;
    height: var(--height);

    &__content {
      @extend .font-bell-heavy;
      text-align: center;
      font-size: 32px;
      line-height: 34px;
      width: 75vw;
      margin-bottom: 20px;

      @media screen and (max-width: 500px) {
        max-width: 100%;
      }
      @media screen and (min-width: 600px) {
        font-size: 3rem;
        line-height: 3.5rem;
      }
    }

    &__text {
      text-align: center;
      font-size: 21px;
      line-height: 25px;
      width: 75vw;

      @media screen and (max-width: 500px) {
        max-width: 100%;
      }
      @media screen and (min-width: 600px) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }

    &__decoration {
      @extend .Page__bg, .Page__bg--top, .Page__bg--right;
      background: url("../../images/x_icon.svg");
      background-size: cover;
      margin-right: 20px;
      margin-top: 20px;
      position: absolute;
      width: 42px;
      height: 42px;
      border: none;
    }

    &__moreInfo {
      text-decoration: none;
      font-size: 21px;
      font-weight: bold;
      color: #0466a5;
    }
  }
}
