.Client {
  .WaitingPage {
    .SignupInitialsPage__decoration-image {
      max-width: 24vw;
      position: absolute;
      width: 100%;
    }

    height: var(--height);
    background-color: white;

    &__title {
      @extend .title, .title--blue;
    }

    &__text {
      @extend .text;
    }

    &__image {
      margin-top: 50px;
      width: 100%;
    }
  }
}
