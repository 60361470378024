.Client {
  .HomePage {
    @extend .flex, .flex--center, .flex--middle, .flex--column;

    width: 100vw;
    height: var(--height);
    overflow: hidden;

    &__top {
      @extend .flex, .flex--center, .flex--middle, .flex--column;
      height: 50%;
      width: 100vw;

      @media screen and (max-width: 320px) {
        flex-flow: row wrap;
      }

      @media screen and (min-width: 600px) {
        height: 50%;
        font-size: 3.5rem;
        align-items: center;
        flex-flow: column;
        margin-top: 2rem;
      }
    }

    &__bottom {
      height: 50%;

      @media screen and (min-width: 760px) {
        height: 50%;
      }
    }

    &__logo {
      width: clamp(200px, 40vh, 300px);
      margin: 0 auto;

      @media screen and (max-width: 320px) {
        max-width: 80%;
      }

      @media screen and (min-width: 600px) {
        max-width: 60%;
        width: auto;
        margin-bottom: 1rem;
      }
    }

    &__title {
      font-size: 1.5rem;
      margin-top: 1rem;
      letter-spacing: 4px;
      text-transform: uppercase;

      @media screen and (min-width: 600px) {
        font-size: 2.2rem;
      }
    }

    &__content {
      @extend .font-bell-heavy;
      max-width: 75vw;
      text-align: center;
      font-size: 26px;
      line-height: 28px;
      margin: 0 auto;
      margin-bottom: 35px;
      margin-top: -20px;

      @media screen and (max-width: 320px) {
        max-width: 90vw;
      }

      @media screen and (min-width: 600px) {
        max-width: 60vw;
        font-size: 32px;
        line-height: 38px;
        padding: 0 2rem;
      }
    }

    &__buttons {
      @extend .flex;

      z-index: 1;
      max-width: 320px;
      margin: 0 auto;

      @media screen and (max-width: 320px) {
        max-width: 230px;

        &.button {
          color: var(--darkshow-color);
          font-size: 28px;
          line-height: 40px;
        }
      }

      @media screen and (min-width: 600px) {
        max-width: 400px;
      }
    }

    &__decoration {
      @extend .Page__bg, .Page__bg--bottom;
      width: 100vw;
      height: 100%;
      object-fit: cover;
      object-position: top;
      bottom: 0;
      margin-top: 5px;
      position: relative;

      @media screen and (min-width: 600px) {
        height: 100%;
        object-fit: contain;
        object-position: top;
        margin-top: 0;
        top: 1.5rem;
      }
    }
  }
}
