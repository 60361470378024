@import "./home.scss";
@import "./waiting.scss";
@import "./sorry.scss";
@import "./question.scss";
@import "./final.scss";
@import "./signup/ImageForm.scss";
@import "./signup/InitialsForm.scss";
@import "./waves.scss";

.Client {
  &.LightshowPage {
    @extend .lightshow;
  }

  &.WinnerslightPage {
    @extend .lightshow;

    animation-duration: 0.3s;
  }

  .Page__content {
    //margin: 0 auto;
    //overflow: hidden;
    position: relative;
    //min-height: 100%;
  }
}
