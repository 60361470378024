.Admin {
  &__header {
    background-color: #00c1f3;
    color: #fff;
    width: 100vw;
    padding: 10px 0;
    font-size: 25px;
    text-align: center;
    margin-bottom: 2rem;

    .Indicator {
      display: inline-block;
    }
  }

  &__confirm {
    position: fixed;
    min-width: 100vw;
    background-color: #eee;
  }

  &__login {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 0px;

    &-title {
      color: #00c1f3;
      font-family: "bell_slim_heavy";
      font-size: 40px;
      letter-spacing: 0;
      line-height: 43px;
      text-align: center;
      font-weight: 500;
    }

    .button--long {
      height: 70px;
      margin-bottom: 20px;
    }

    &-password {
      box-sizing: border-box;
      height: 70px;
      width: 30rem;
      border: 1px solid #00c1f3;
      background-color: #ffffff;
      color: #828282;
      font-family: Helvetica;
      font-size: 24px;
      letter-spacing: 0;
      line-height: 34px;
      text-align: center;
      outline: none;
      margin-bottom: 16px;

      &:focus::-webkit-input-placeholder {
        opacity: 0;
      }
    }
  }

  .Players {
    @extend .flex, .flex--column;

    margin-top: 0px;

    h5 {
      font-size: 1.5rem;
      margin: 0px 0 14px 0;
      font-family: "bell_slim_heavy";
      text-align: center;
    }

    &__item {
      @extend .flex, .flex--center;

      flex: 0 0 28px;
      max-width: 90vw;
      line-height: 20px;
      margin-bottom: 10px;

      @media screen and (min-width: 768px) {
        flex: 0 0 40px;
      }

      &-action {
        @extend .button;

        flex: 0 0 20px;
        padding: 0px 10px;
        margin-right: 10px;
        margin-left: 10px;

        svg {
          max-height: 20px;
          max-width: 20px;
          color: #fff;
          padding-top: 4px;
        }

        &--red {
          @extend .button--red;
        }

        &--green {
          @extend .button--green;
        }
      }

      &-name {
        font-size: 20px;
        line-height: 28px;
        width: 50vw;
        text-align: center;
        padding: 0 20px;
        border: 2px solid #0366a4;
        font-family: "bell_slim_heavy";

        @media screen and (min-width: 768px) {
          font-size: 24px;
          line-height: 32px;
        }

        &.reviewed {
          border-color: #24a403;
        }
      }
    }
  }
}
