.Client {
  .SurveyPage {
    @extend .flex, .flex--center, .flex--column;
    justify-content: space-evenly;
    position: relative;
    width: 100vw;
    overflow-y: scroll;
    padding-top: 10vh;
    text-transform: uppercase;

    @media screen and (min-width: 600px) {
      padding-top: 6vh;
    }

    &__content {
      @extend .font-bell-heavy;
      position: relative;
      text-align: center;
      font-size: 33px;
      line-height: 35px;
      width: 80vw;
      height: 15vh;
      letter-spacing: 4px;

      @media screen and (max-width: 320px) {
        max-width: 100%;
        font-size: 30px;
      }

      @media screen and (max-width: 600px) {
        max-width: 90%;
      }

      @media screen and (min-width: 600px) {
        font-size: 3.5rem;
        line-height: 3.6rem;
        letter-spacing: 0.6rem;
        width: 80vw;
        height: 20vh;
        margin: 0;
      }
    }

    &__text {
      @extend .font-bell-heavy;
      position: relative;
      text-align: center;
      font-size: 25px;
      line-height: 27px;
      letter-spacing: 3px;
      width: 80vw;
      padding-bottom: 1rem;
      color: var(--lightshow-color);

      @media screen and (max-width: 320px) {
        max-width: 100%;
        font-size: 21px;
      }

      @media screen and (max-width: 500px) {
        max-width: 100%;
      }

      @media screen and (min-width: 600px) {
        font-size: 1.5rem;
        line-height: 1.2;
        padding: 2rem 1rem;
        letter-spacing: 0.4rem;
        max-width: 85vw;
      }
    }

    &__image {
      position: relative;
      width: 55vw;
      padding-top: 3rem;
      mix-blend-mode: screen;

      @media screen and (min-width: 600px) {
        width: 45vw;
        padding-top: 0;
      }
    }

    &__bottomDecoration {
      @extend .Page__bg, .Page__bg--bottom, .Page__bg--right;
      position: fixed;
      padding: 0 24px 24px 0;
      width: 25vw;

      @media screen and (min-width: 600px) {
        width: 20vw;
        margin-right: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}
