.AnswerIndicator {
  display: none;
  @extend .font-bell-heavy;
  @extend .flex, .flex--column, .flex--center;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: var(--height);
  width: 100vw;
  z-index: 10;
  background: linear-gradient(135deg, #070942d7 0%, #161616a0 100%);
  @keyframes showIndicator {
    0% {
      opacity: 0;
    }
    45% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }

  @keyframes moveUpIndicator {
    0% {
      transform: translate3d(0, 100vh, 0);
      opacity: 0;
    }
    10% {
      transform: translate3d(0, 100vh, 0);
      opacity: 0;
    }
    45% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    65% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    100% {
      transform: translate3d(0, -100vh, 0);
      opacity: 0;
    }
  }

  &__show {
    @extend .AnswerIndicator;
    opacity: 0;
    display: flex;

    animation: showIndicator 4.5s forwards;

    &-img {
      &--loser {
        margin-bottom: -117px;
        width: 301px;
        height: 265px;
        transform: translate3d(0, 100vh, 0);
        animation: moveUpIndicator 4s forwards;

        @media screen and (max-width: 320px) {
          margin: 1rem;
        }
      }

      &--winner {
        width: 222px;
        height: 186px;
        transform: translate3d(0, 100vh, 0);
        animation: moveUpIndicator 4s forwards;

        @media screen and (max-width: 320px) {
          margin: 1rem;
        }
      }
    }
    &-text {
      color: #ffffff;
      transform: translate3d(0, 100vh, 0);
      animation: moveUpIndicator 4s forwards;

      &--loser {
        font-size: 80px;

        @media screen and (max-width: 320px) {
          font-size: 60px;
        }
      }
      &--winner {
        font-size: 40px;
        @media screen and (max-width: 320px) {
          font-size: 20px;
        }
        @media screen and (mni-width: 600px) {
          font-size: 60px;
        }
      }
    }
  }
}
