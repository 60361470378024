.waveContainer {
  background: linear-gradient(
    135deg,
    rgba(3, 106, 167, 1) 0%,
    rgba(0, 156, 212, 1) 100%
  );
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow-x: hidden;
}

.wave3 {
  background: url("../../images/wave_one.svg") repeat-x;
  position: absolute;
  bottom: 0;
  left: -10px;
  width: 3840px;
  height: 563px;
  animation: wave 120s infinite;
}
.wave4 {
  background: url("../../images/wave_two.svg") repeat-x;
  position: absolute;
  bottom: 0;
  left: -10px;
  width: 3840px;
  height: 256px;
  animation: wave 100s infinite;
}

@keyframes wave {
  0% {
    left: -10px;
  }
  50% {
    left: -1500px;
  }

  100% {
    left: -10px;
  }
}
