.FinalPage {
  // @extend .gradient-bg;
  @extend .font-bell-heavy;

  color: #ffffff;
  text-align: center;
  text-transform: uppercase;

  &__container {
    @extend .flex, .flex--column, .flex--middle, .flex--center;

    height: 100%;
  }

  &__title {
    flex: 0 1 50px;
    // padding-top: 60px;
    // padding-bottom: 20px;
    font-size: 40px;
    z-index: 1;

    @media screen and (min-width: 600px) {
      font-size: 3.5rem;
    }
  }

  &__score {
    flex: 0 1 60px;
    font-size: 55px;
    padding: 10px 0;
    z-index: 1;
    color: var(--lightshow-color);

    @media screen and (min-width: 600px) {
      font-size: 5rem;
    }
  }

  &__avatar {
    flex: 0 1 400px;
    position: relative;
    padding-top: 80px;
    max-width: 360px;
    text-align: center;
    z-index: 1;

    @media screen and (min-width: 600px) {
      padding-top: 40px;
      max-width: 400px;
    }

    &-image {
      height: 260px;
      width: 260px;
      border-radius: 260px;
      border: 6px solid #ffffff;

      @media screen and (min-width: 600px) {
        height: 320px;
        width: 320px;
        border-radius: 320px;
      }
    }

    &-crown {
      position: absolute;
      top: 15px;
      left: -20px;
    }
  }

  &__player-message {
    margin-top: 20px;
    font-size: 40px;
    z-index: 1;

    @media screen and (min-width: 600px) {
      font-size: 3rem;
    }
  }

  &__canvas {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
  }
}
