.Client {
  .SorryPage {
    @extend .flex, .flex--center, .flex--middle, .flex--column;
    width: 100vw;
    height: var(--height);
    overflow: hidden;
    justify-content: center;
    align-items: center;

    &__title {
      @extend .title;
      font-size: 2rem;
      line-height: 32px;
      max-width: 340px;
      padding: 5px 0;
      text-transform: uppercase;

      @media screen and (min-width: 600px) {
        font-size: 3rem;
        line-height: 3.5rem;
        max-width: 500px;
      }
    }

    &__sub-title {
      @extend .sub-title;
      font-size: 24px;
      line-height: 1.2;
      max-width: 340px;
      padding: 0 1rem;
      color: var(--lightshow-color);
      text-transform: uppercase;

      @media screen and (min-width: 600px) {
        font-size: 2rem;
        max-width: 500px;
      }
    }

    &__decoration {
      width: 100vw;
      height: 100%;
      object-fit: cover;
      object-position: top;
      bottom: 0;
      margin-top: 0px;
      position: relative;

      max-width: 100%;

      @media screen and (min-width: 768px) {
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
