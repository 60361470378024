.animation {
  width: 100vw;
  //  height: 100vh;

  & > div {
    float: left;
    position: absolute;
    width: 100vw;
    height: var(--height);
    top: 0;
  }

  &__static {
  }

  &__none {
    & > div {
      transition: opacity 0.01s ease;

      &.current {
        opacity: 0;
      }
    }

    &.animate {
      & > div {
        &.current {
          opacity: 1;
        }
      }
    }
  }

  &__fade {
    & > div {
      &.previous {
        opacity: 1;
      }
      &.current {
        opacity: 0;
      }
    }

    &.animate {
      & > div {
        &.previous {
          animation: fadeout 0.5s ease;
          opacity: 0;
        }
        &.current {
          animation: fadein 0.5s ease;
          animation-delay: 0.5s;
          opacity: 0;
        }
      }
    }

    @keyframes fadeout {
      to {
        opacity: 0;
      }
    }
    @keyframes fadein {
      to {
        opacity: 1;
      }
    }
  }

  &__crossfade {
    & > div {
      &.previous {
        opacity: 1;
      }
      &.current {
        opacity: 0;
      }
    }

    &.animate {
      & > div {
        &.previous {
          animation: fadeout 0.75s ease;
          opacity: 0;
        }
        &.current {
          animation: fadein 0.75s ease;
          opacity: 0;
        }
      }
    }

    @keyframes fadeout {
      to {
        opacity: 0;
      }
    }
    @keyframes fadein {
      to {
        opacity: 1;
      }
    }
  }

  &__slideLeft {
    & > div {
      transition: transform 0.4s ease;

      &.previous {
        transform: translateX(0);
      }

      &.current {
        left: 100vw;
        transform: translateX(100vw);
      }
    }

    &.animate {
      & > div {
        &.previous {
          transform: translateX(-100vw);
        }

        &.current {
          transform: translateX(-100vw);
        }
      }
    }
  }
  &__slideRight {
    & > div {
      transition: transform 0.4s ease;

      &.previous {
        transform: translateX(0vw);
      }

      &.current {
        left: -100vw;
        transform: translateX(-100vw);
      }
    }

    &.animate {
      & > div {
        &.previous {
          transform: translateX(100vw);
        }

        &.current {
          transform: translateX(100vw);
        }
      }
    }
  }

  &__slideDown {
    & > div {
      transition: transform 0.4s ease;

      &.previous {
        transform: translateY(0vw);
      }

      &.current {
        top: -100vw;
        transform: translateY(-100vw);
      }
    }

    &.animate {
      & > div {
        &.previous {
          transform: translateY(0);
        }

        &.current {
          transform: translateY(100vw);
        }
      }
    }
  }
}
