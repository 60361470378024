$timer-size: 150px;
$timer-bigger-size: 190px;
/* Sets the containers height and width */
.Timer {
  position: relative;
  height: $timer-size;
  width: $timer-size;

  @media screen and (min-width: 600px) {
    height: $timer-bigger-size;
    width: $timer-bigger-size;
  }
}

/* Removes SVG styling that would hide the time label */
.Timer__circle {
  fill: none;
  stroke: none;
}

/* The SVG path that displays the timer's progress */
.Timer__path-elapsed {
  stroke-width: 7px;
  stroke: #ffffff;
}

.Timer__label {
  position: absolute;

  /* Size should match the parent container */
  width: $timer-size;
  height: $timer-size;

  /* Keep the label aligned to the top */
  top: 0;

  /* Create a flexible box that centers content vertically and horizontally */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Sort of an arbitrary number; adjust to your liking */
  font-size: 65px;

  @media screen and (min-width: 600px) {
    height: $timer-bigger-size;
    width: $timer-bigger-size;
    font-size: 5rem;
  }
}

.Timer__path-remaining {
  /* Just as thick as the original ring */
  stroke-width: 8px;

  /* Rounds the line endings to create a seamless circle */
  stroke-linecap: round;

  /* Makes sure the animation starts at the top of the circle */
  transform: rotate(90deg);
  transform-origin: center;

  /* One second aligns with the speed of the countdown timer */
  transition: 1s linear all;

  /* Allows the ring to change color when the color value updates */
  stroke: currentColor;
}

// Use it to flip the timer left-to-right
// .Timer__svg {
//
//  transform: scaleX(-1);
// }

.Timer__path-remaining.info {
  color: #00c1f3;
}

.Timer__path-remaining.warning {
  color: #f37a00;
}

.Timer__path-remaining.alert {
  color: #f30029;
}
